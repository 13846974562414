import React, { useState, useCallback, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useUploadContext } from './uploadContext'
import { uploadApi } from './api'
import { UPLOAD_STAGES, POLLING_CONFIG } from './constants'
import CryptoJS from 'crypto-js'
import { useUploadSelector } from './selectors'

export const useFileUpload = (file) => {
  const { dispatch } = useUploadContext()
  const [uuid, setUuid] = useState(null)
  const pollingTimeoutRef = useRef(null)
  const pollingStartTimeRef = useRef(null)

  const calculateMD5 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const buffer = e.target.result
        const wordArray = CryptoJS.lib.WordArray.create(buffer)
        const md5 = CryptoJS.MD5(wordArray)
        resolve(CryptoJS.enc.Base64.stringify(md5))
      }
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    })
  }

  const startPolling = useCallback(
    (uuid) => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current)
      }

      pollingStartTimeRef.current = Date.now()

      const pollStatus = async () => {
        try {
          const status = await uploadApi.checkStatus(uuid)

          // Only update status if we have real metadata
          if (status.processing_status !== 'pending') {
            dispatch({
              type: 'UPDATE_STATUS',
              payload: {
                uuid,
                stage:
                  status.processing_status === 'completed'
                    ? UPLOAD_STAGES.COMPLETED
                    : UPLOAD_STAGES.PROCESSING,
                metadata: status,
              },
            })
          }

          // Check if we should continue polling
          const elapsedTime = Date.now() - pollingStartTimeRef.current
          const maxDuration =
            status.processing_status === 'processing'
              ? POLLING_CONFIG.MAX_DURATION.PROCESSING
              : POLLING_CONFIG.MAX_DURATION.METADATA

          if (
            status.processing_status !== 'completed' &&
            elapsedTime < maxDuration
          ) {
            pollingTimeoutRef.current = setTimeout(
              pollStatus,
              POLLING_CONFIG.INTERVAL
            )
          }
        } catch (error) {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              uuid,
              ...error,
            },
          })
        }
      }

      pollStatus()
    },
    [dispatch]
  )

  const upload = useCallback(async () => {
    if (!file) return

    try {
      // Calculate MD5
      const md5 = await calculateMD5(file)

      // Generate upload policy
      const { url, fields, metadata_path } = await uploadApi.generatePolicy({
        name: file.name,
        type: file.type,
        md5,
      })

      // Create file entry in context
      const fileUuid = metadata_path.split('/')[1].replace('.json', '')
      setUuid(fileUuid)

      dispatch({
        type: 'ADD_FILE',
        payload: {
          uuid: fileUuid,
          file: {
            name: file.name,
            size: file.size,
            type: file.type,
          },
        },
      })

      // Upload file
      await uploadApi.uploadFile(url, fields, file, md5, (progress) => {
        dispatch({
          type: 'UPDATE_PROGRESS',
          payload: {
            uuid: fileUuid,
            progress,
          },
        })
      })

      // Start polling for status
      startPolling(fileUuid)

      return fileUuid
    } catch (error) {
      const fileUuid = uuid || uuidv4()
      dispatch({
        type: 'SET_ERROR',
        payload: {
          uuid: fileUuid,
          ...error,
        },
      })
      throw error
    }
  }, [file, dispatch, startPolling, uuid])

  const retry = useCallback(async () => {
    if (!file || !uuid) return

    // Clear existing error
    dispatch({
      type: 'SET_ERROR',
      payload: {
        uuid,
        code: null,
        message: null,
        details: null,
      },
    })

    return upload()
  }, [file, uuid, upload, dispatch])

  useEffect(() => {
    return () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current)
      }
    }
  }, [])

  return {
    upload,
    retry,
    uuid,
  }
}

// Example usage component
export const FileUploader = ({ file }) => {
  const { upload, retry, uuid } = useFileUpload(file)
  const { selectFileByUuid } = useUploadSelector()
  const fileState = uuid ? selectFileByUuid(uuid) : null

  const handleUpload = async () => {
    try {
      await upload()
    } catch (error) {
      console.error('Upload failed:', error)
    }
  }

  const handleRetry = async () => {
    try {
      await retry()
    } catch (error) {
      console.error('Retry failed:', error)
    }
  }

  if (!fileState) {
    return <button onClick={handleUpload}>Upload</button>
  }

  return (
    <div>
      {fileState.file.stage === UPLOAD_STAGES.UPLOADING && (
        <div>Uploading: {Math.round(fileState.file.progress)}%</div>
      )}

      {fileState.file.stage === UPLOAD_STAGES.PROCESSING && (
        <div>Processing...</div>
      )}

      {fileState.file.stage === UPLOAD_STAGES.COMPLETED && (
        <div>Upload complete!</div>
      )}

      {fileState.error && (
        <div>
          <div>Error: {fileState.error.message}</div>
          {fileState.error.retryable && (
            <button onClick={handleRetry}>Retry</button>
          )}
        </div>
      )}
    </div>
  )
}
