export const parseError = (error) => {
  // Handle policy generation errors (JSON)
  if (error.response?.data?.errors) {
    return {
      code: error.response.data.errors[0].code,
      message: error.response.data.errors[0].message,
      details: error.response.data.errors,
      retryable: true,
    }
  }

  // Handle S3-style XML errors
  if (error.response?.headers['content-type']?.includes('application/xml')) {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(error.response.data, 'text/xml')
    const code = xmlDoc.querySelector('Code')?.textContent
    const message = xmlDoc.querySelector('Message')?.textContent

    return {
      code,
      message,
      details: error.response.data,
      retryable: code !== 'InvalidPolicyDocument',
    }
  }

  // Handle network or unexpected errors
  return {
    code: 'UNKNOWN_ERROR',
    message: 'An unexpected error occurred',
    details: error,
    retryable: true,
  }
}
