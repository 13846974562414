import React from 'react'
import { FileUploader } from './useFileUpload'
import { UploadProvider } from './uploadContext'

/**
 * A simple component to test the function of file upload feature
 * */
export default function SandboxUploadComponent(props) {
  const [file, setFile] = React.useState(null)

  return (
    <UploadProvider>
      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
      {file && <FileUploader file={file} />}
    </UploadProvider>
  )
}
