export const UPLOAD_STAGES = {
  UPLOADING: 'uploading',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  PASSWORD_REQUIRED: 'password_required'
};

export const POLLING_CONFIG = {
  INTERVAL: 5000, // 5 seconds
  MAX_DURATION: {
    PROCESSING: 5 * 60 * 1000, // 5 minutes
    METADATA: 2 * 60 * 1000    // 2 minutes
  }
};