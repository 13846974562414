import { useUploadContext } from './uploadContext'
import { UPLOAD_STAGES } from './constants'

export const useUploadSelector = () => {
  const { state } = useUploadContext();

  const selectFileByUuid = (uuid) => ({
    file: state.files[uuid],
    error: state.errors[uuid]
  });

  const selectActiveUploads = () => {
    return Object.entries(state.files)
      .filter(([_, file]) => file.stage === UPLOAD_STAGES.UPLOADING ||
                            file.stage === UPLOAD_STAGES.PROCESSING)
      .map(([uuid, file]) => ({
        uuid,
        ...file,
        error: state.errors[uuid]
      }));
  };

  const selectCompletedUploads = () => {
    return Object.entries(state.files)
      .filter(([_, file]) => file.stage === UPLOAD_STAGES.COMPLETED)
      .map(([uuid, file]) => ({
        uuid,
        ...file,
        error: state.errors[uuid]
      }));
  };

  return {
    selectFileByUuid,
    selectActiveUploads,
    selectCompletedUploads
  };
};